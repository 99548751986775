"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.co2VehicleSubgroups = void 0;
const validators_1 = require("@oresundsbron/validators");
exports.co2VehicleSubgroups = {
    '': [],
    [validators_1.Co2VehicleGroup.Four]: [
        {
            label: validators_1.Co2VehicleSubgroup.UD,
            value: validators_1.Co2VehicleSubgroup.UD,
        },
        {
            label: validators_1.Co2VehicleSubgroup.RD,
            value: validators_1.Co2VehicleSubgroup.RD,
        },
        {
            label: validators_1.Co2VehicleSubgroup.LH,
            value: validators_1.Co2VehicleSubgroup.LH,
        },
    ],
    [validators_1.Co2VehicleGroup.Five]: [
        {
            label: validators_1.Co2VehicleSubgroup.RD,
            value: validators_1.Co2VehicleSubgroup.RD,
        },
        {
            label: validators_1.Co2VehicleSubgroup.LH,
            value: validators_1.Co2VehicleSubgroup.LH,
        },
    ],
    [validators_1.Co2VehicleGroup.Nine]: [
        {
            label: validators_1.Co2VehicleSubgroup.RD,
            value: validators_1.Co2VehicleSubgroup.RD,
        },
        {
            label: validators_1.Co2VehicleSubgroup.LH,
            value: validators_1.Co2VehicleSubgroup.LH,
        },
    ],
    [validators_1.Co2VehicleGroup.Ten]: [
        {
            label: validators_1.Co2VehicleSubgroup.RD,
            value: validators_1.Co2VehicleSubgroup.RD,
        },
        {
            label: validators_1.Co2VehicleSubgroup.LH,
            value: validators_1.Co2VehicleSubgroup.LH,
        },
    ],
};
