"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FuelTypeIdEnum = exports.FuelTypeEnum = void 0;
var FuelTypeEnum;
(function (FuelTypeEnum) {
    FuelTypeEnum["Diesel"] = "Diesel";
    FuelTypeEnum["Gasoline"] = "Gasoline";
    FuelTypeEnum["GasolineHybrid"] = "Gasolinehybrid";
    FuelTypeEnum["DieselHybrid"] = "Dieselhybrid";
    FuelTypeEnum["Electric"] = "Electric";
    FuelTypeEnum["Biodiesel"] = "Biodiesel";
    FuelTypeEnum["Biogasoline"] = "Biogasoline";
    FuelTypeEnum["Hydrogen"] = "Hydrogen";
    FuelTypeEnum["NaturalGas"] = "Naturalgas";
    FuelTypeEnum["Parafin"] = "Parafin";
    FuelTypeEnum["Other"] = "Other";
})(FuelTypeEnum = exports.FuelTypeEnum || (exports.FuelTypeEnum = {}));
exports.FuelTypeIdEnum = {
    [FuelTypeEnum.Diesel]: 2,
    [FuelTypeEnum.Gasoline]: 1,
    [FuelTypeEnum.GasolineHybrid]: 7,
    [FuelTypeEnum.DieselHybrid]: 8,
    [FuelTypeEnum.Electric]: 5,
    [FuelTypeEnum.Biodiesel]: 10,
    [FuelTypeEnum.Biogasoline]: 11,
    [FuelTypeEnum.Hydrogen]: 6,
    [FuelTypeEnum.NaturalGas]: 4,
    [FuelTypeEnum.Parafin]: 3,
    [FuelTypeEnum.Other]: 9,
};
