"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fuelTypes = void 0;
const FuelTypeEnums_1 = require("../types/FuelTypeEnums");
exports.fuelTypes = [
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Diesel],
        label: FuelTypeEnums_1.FuelTypeEnum.Diesel,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Gasoline],
        label: FuelTypeEnums_1.FuelTypeEnum.Gasoline,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.GasolineHybrid],
        label: FuelTypeEnums_1.FuelTypeEnum.GasolineHybrid,
        tariffClassification: 51,
        hybrid: true,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.DieselHybrid],
        label: FuelTypeEnums_1.FuelTypeEnum.DieselHybrid,
        tariffClassification: 51,
        hybrid: true,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Electric],
        label: FuelTypeEnums_1.FuelTypeEnum.Electric,
        tariffClassification: 54,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Biodiesel],
        label: FuelTypeEnums_1.FuelTypeEnum.Biodiesel,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Biogasoline],
        label: FuelTypeEnums_1.FuelTypeEnum.Biogasoline,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Hydrogen],
        label: FuelTypeEnums_1.FuelTypeEnum.Hydrogen,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.NaturalGas],
        label: FuelTypeEnums_1.FuelTypeEnum.NaturalGas,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Parafin],
        label: FuelTypeEnums_1.FuelTypeEnum.Parafin,
        tariffClassification: 51,
    },
    {
        id: FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Other],
        label: FuelTypeEnums_1.FuelTypeEnum.Other,
        tariffClassification: 51,
    },
];
