"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listVehicleTypes = void 0;
const vehicleTypes_1 = require("./consts/vehicleTypes");
const formatVehicleType_1 = require("./utils/formatVehicleType");
const getWeightCategory_1 = require("./utils/getWeightCategory");
const listVehicleTypes = () => {
    return vehicleTypes_1.vehicleTypes.map((vehicleType) => ({
        ...(0, formatVehicleType_1.formatVehicleType)(vehicleType),
        weightCategory: (0, getWeightCategory_1.getWeightCategory)(vehicleType?.id),
    }));
};
exports.listVehicleTypes = listVehicleTypes;
