"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleTypes = void 0;
const validators_1 = require("@oresundsbron/validators");
exports.vehicleTypes = [
    {
        id: validators_1.VehicleTypeId.Motorcycle,
        uneceClass: [validators_1.UneceClass.L],
        vehicleGroup: validators_1.VehicleGroup.Six,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'motorcycle',
        value: '1',
    },
    {
        id: validators_1.VehicleTypeId.PassengerVehicle,
        uneceClass: [validators_1.UneceClass.M1],
        vehicleGroup: validators_1.VehicleGroup.One,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'passengerVehicle',
        value: '2',
    },
    {
        id: validators_1.VehicleTypeId.LightGoodsVehicle,
        uneceClass: [validators_1.UneceClass.N1],
        vehicleGroup: validators_1.VehicleGroup.Two,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'lightGoodsVehicle',
        value: '3',
    },
    {
        id: validators_1.VehicleTypeId.BusOver3500kg,
        uneceClass: [validators_1.UneceClass.M2, validators_1.UneceClass.M3],
        vehicleGroup: validators_1.VehicleGroup.Three,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'busOver3500kg',
        value: '4',
    },
    {
        id: validators_1.VehicleTypeId.BusUnder3500kg,
        uneceClass: [validators_1.UneceClass.M2],
        vehicleGroup: validators_1.VehicleGroup.Three,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'busUnder3500kg',
        value: '5',
    },
    {
        id: validators_1.VehicleTypeId.MobileHome,
        uneceClass: [validators_1.UneceClass.M1],
        vehicleGroup: validators_1.VehicleGroup.Three,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'mobileHome',
        value: '6',
    },
    {
        id: validators_1.VehicleTypeId.MobileHomeOver3500kg,
        uneceClass: [validators_1.UneceClass.M2, validators_1.UneceClass.M3],
        vehicleGroup: validators_1.VehicleGroup.Three,
        weightCategory: validators_1.WeightCategory.Under3500kg,
        label: 'mobileHomeOver3500kg',
        value: '7',
    },
    {
        id: validators_1.VehicleTypeId.HeavyTruck3_5To12Ton,
        uneceClass: [validators_1.UneceClass.N2],
        vehicleGroup: validators_1.VehicleGroup.Four,
        weightCategory: validators_1.WeightCategory.Over3500kgUnder12000kg,
        label: 'heavyTruck3_5To12Ton',
        value: '8',
    },
    {
        id: validators_1.VehicleTypeId.HeavyTruckOver12Ton,
        uneceClass: [validators_1.UneceClass.N3],
        vehicleGroup: validators_1.VehicleGroup.Five,
        weightCategory: validators_1.WeightCategory.Over12000kgToInfinity,
        label: 'heavyTruckOver12Ton',
        value: '9',
    },
];
