import { Contract } from '@oresundsbron/api';
import { constants } from '@oresundsbron/validators';
import create from 'zustand';

export type StorePrice = {
  currency: string;
  price: number;
  priceInclVat: number;
};
export type StoreContract = Omit<
  Contract,
  'currency' | 'priceInclVat' | 'price'
> & {
  prices: StorePrice[];
};

export type ContractsStore = {
  contracts: StoreContract[];
  main: StoreContract;
  mainCopy: StoreContract;
};

export type CampaignDiscountResult = {
  currency: string;
  price: number;
  contractType: string;
  productCode: string;
  priceInclVAT: number;
};

const OresundPayContract: StoreContract = {
  key: 'oresundpay',
  value: constants.OresundPAY,
  prices: [
    { currency: 'SEK', price: 0, priceInclVat: 0 },
    { currency: 'DKK', price: 0, priceInclVat: 0 },
  ],
};

const OresundPayBusinessContract: StoreContract = {
  key: 'oresundpay',
  value: constants.OresundPAYBusiness,
  prices: [
    { currency: 'SEK', price: 0, priceInclVat: 0 },
    { currency: 'DKK', price: 0, priceInclVat: 0 },
  ],
};

const StorebeltContract: StoreContract = {
  key: 'storebelt',
  value: constants.Storebelt,
  prices: [
    { currency: 'SEK', price: 0, priceInclVat: 0 },
    { currency: 'DKK', price: 0, priceInclVat: 0 },
  ],
};

export const useContractsStore = create<ContractsStore>(() => ({
  contracts: [],
  main: {} as StoreContract,
  mainCopy: {} as StoreContract,
}));

export const setMainContract = (main: StoreContract) =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.some((c) => c.value === main.value)
      ? state.contracts
      : [{ ...main }, ...state.contracts],
    main,
    mainCopy: main,
  }));

export const addDiscountContract = (c: StoreContract) =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.map((con) => (con.value === c.value ? c : con)),
    main: c,
    mainCopy: c,
  }));

export const discountMainContract = (discount: CampaignDiscountResult) =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.map((con) =>
      con.value === state.main.value
        ? {
            ...state.main,
            prices: state.main.prices.map((p) =>
              p.currency === discount.currency
                ? {
                    ...p,
                    price: discount.price,
                    priceInclVat: discount.priceInclVAT,
                  }
                : { ...p }
            ),
          }
        : con
    ),
    main: {
      ...state.main,
      prices: state.main.prices.map((p) =>
        p.currency === discount.currency
          ? {
              ...p,
              price: discount.price,
              priceInclVat: discount.priceInclVAT,
            }
          : { ...p }
      ),
    },
  }));

export const removeDiscountMainContract = () =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.map((con) =>
      con.value === state.main.value ? { ...state.mainCopy } : con
    ),
    main: { ...state.mainCopy },
  }));

export const addOresundPay = () =>
  useContractsStore.setState((state) => ({
    contracts: [...state.contracts, OresundPayContract],
  }));

export const addOresundPayBusiness = () =>
  useContractsStore.setState((state) => ({
    contracts: [...state.contracts, OresundPayBusinessContract],
  }));

export const addStorebelt = () =>
  useContractsStore.setState((state) => ({
    contracts: [...state.contracts, StorebeltContract],
  }));

export const removeOresundPay = () =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.filter(
      (c) => c.value !== OresundPayContract.value
    ),
  }));

export const removeOresundPayBusiness = () =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.filter(
      (c) => c.value !== OresundPayBusinessContract.value
    ),
  }));

export const removeStorebelt = () =>
  useContractsStore.setState((state) => ({
    contracts: state.contracts.filter(
      (c) => c.value !== StorebeltContract.value
    ),
  }));

export const reset = () =>
  useContractsStore.setState(() => ({
    contracts: [],
    main: {} as StoreContract,
    mainCopy: {} as StoreContract,
  }));

export const getContracts = () => ({
  contracts: useContractsStore.getState().contracts,
  main: useContractsStore.getState().main,
});
