import { jsx as jsx$1, jsxs as jsxs$1 } from '@emotion/react/jsx-runtime';
import { p as parseStylingProps } from '../../dist/jsx-props-9442420c.esm.js';
export { Fragment } from 'react';
import 'fp-ts/Option';
import 'fp-ts/function';
import 'fp-ts/Record';
import 'fp-ts/Array';
import 'fp-ts/boolean';

var jsx = function jsx(type, props, key) {
  return jsx$1(type, parseStylingProps(props), key);
};
var jsxs = function jsxs(type, props, key) {
  return jsxs$1(type, parseStylingProps(props), key);
};

export { jsx, jsxs };
