import React from 'react';

type ConditionalWrapperProps = {
  props?: unknown;
  condition: boolean;
  wrapper: (children: React.ReactNode, props?: unknown) => React.ReactNode;
  children: React.ReactNode;
};

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  props,
  condition,
  wrapper,
  children,
}) => {
  return <>{condition ? wrapper(children, props) : children}</>;
};

export default ConditionalWrapper;
