"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFuelTypeHybridIDs = void 0;
const fuelTypes_1 = require("./consts/fuelTypes");
const listFuelTypeHybridIDs = () => {
    const hybridIDs = fuelTypes_1.fuelTypes
        .filter((item) => item.hybrid)
        .map((item) => item.id.toString());
    return hybridIDs;
};
exports.listFuelTypeHybridIDs = listFuelTypeHybridIDs;
