"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emissionClasses = void 0;
exports.emissionClasses = [
    { id: 1, label: 'Euro1' },
    { id: 2, label: 'Euro2' },
    { id: 3, label: 'Euro3' },
    { id: 4, label: 'Euro4' },
    { id: 5, label: 'Euro5' },
    { id: 6, label: 'Euro6' },
    { id: 15, label: 'Eev' },
];
