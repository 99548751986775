"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTariffClassification = void 0;
const FuelTypeEnums_1 = require("./types/FuelTypeEnums");
const validators_1 = require("@oresundsbron/validators");
const calculateTariffClassification = (vehicleType, fuelType) => {
    const vehicleTypeInt = parseInt(vehicleType || '');
    const isZeroEmission = fuelType === FuelTypeEnums_1.FuelTypeIdEnum[FuelTypeEnums_1.FuelTypeEnum.Electric];
    // All these values originate from this ticket:
    // https://dev.azure.com/oresundsbron/osb-web/_workitems/edit/12250
    const tariffMapping = {
        [validators_1.VehicleTypeId.Motorcycle]: { zeroEmission: '63', regular: '63' },
        [validators_1.VehicleTypeId.PassengerVehicle]: { zeroEmission: '14', regular: '12' },
        [validators_1.VehicleTypeId.LightGoodsVehicle]: { zeroEmission: '24', regular: '22' },
        [validators_1.VehicleTypeId.BusOver3500kg]: { zeroEmission: '34', regular: '31' },
        [validators_1.VehicleTypeId.BusUnder3500kg]: { zeroEmission: '34', regular: '32' },
        [validators_1.VehicleTypeId.MobileHome]: { zeroEmission: '14', regular: '12' },
        [validators_1.VehicleTypeId.MobileHomeOver3500kg]: { zeroEmission: '34', regular: '32' },
        [validators_1.VehicleTypeId.HeavyTruck3_5To12Ton]: { zeroEmission: '44', regular: '41' },
        [validators_1.VehicleTypeId.HeavyTruckOver12Ton]: { zeroEmission: '54', regular: '51' },
    };
    const tariff = tariffMapping[vehicleTypeInt];
    if (!tariff) {
        return '71';
    }
    return isZeroEmission ? tariff.zeroEmission : tariff.regular;
};
exports.calculateTariffClassification = calculateTariffClassification;
