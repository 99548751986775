"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatVehicleType = void 0;
const formatVehicleType = (vehicleType) => {
    return {
        id: vehicleType?.id,
        label: vehicleType?.label,
        value: vehicleType?.id?.toString(),
        uneceClass: vehicleType?.uneceClass,
        vehicleGroup: vehicleType?.vehicleGroup,
    };
};
exports.formatVehicleType = formatVehicleType;
