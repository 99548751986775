"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2CutoffDate = exports.defaultCO2EmissionClassValue = exports.numberOfAxelsOptions = exports.calculateTariffClassification = exports.calculateTspProductCodeAndEngineCharacteristics = exports.listCo2EmissionClasses = exports.listEmissionClasses = exports.listFuelTypes = exports.listFuelTypeHybridIDs = exports.listCo2VehicleSubgroups = exports.listVehicleTypes = void 0;
var listVehicleTypes_1 = require("./listVehicleTypes");
Object.defineProperty(exports, "listVehicleTypes", { enumerable: true, get: function () { return listVehicleTypes_1.listVehicleTypes; } });
var listCo2VehicleSubgroups_1 = require("./listCo2VehicleSubgroups");
Object.defineProperty(exports, "listCo2VehicleSubgroups", { enumerable: true, get: function () { return listCo2VehicleSubgroups_1.listCo2VehicleSubgroups; } });
var listFuelTypeHybridIDs_1 = require("./listFuelTypeHybridIDs");
Object.defineProperty(exports, "listFuelTypeHybridIDs", { enumerable: true, get: function () { return listFuelTypeHybridIDs_1.listFuelTypeHybridIDs; } });
var listFuelTypes_1 = require("./listFuelTypes");
Object.defineProperty(exports, "listFuelTypes", { enumerable: true, get: function () { return listFuelTypes_1.listFuelTypes; } });
var listEmissionClasses_1 = require("./listEmissionClasses");
Object.defineProperty(exports, "listEmissionClasses", { enumerable: true, get: function () { return listEmissionClasses_1.listEmissionClasses; } });
var listCo2EmissionClasses_1 = require("./listCo2EmissionClasses");
Object.defineProperty(exports, "listCo2EmissionClasses", { enumerable: true, get: function () { return listCo2EmissionClasses_1.listCo2EmissionClasses; } });
var calculateTspProductCodeAndEngineCharacteristics_1 = require("./calculateTspProductCodeAndEngineCharacteristics");
Object.defineProperty(exports, "calculateTspProductCodeAndEngineCharacteristics", { enumerable: true, get: function () { return calculateTspProductCodeAndEngineCharacteristics_1.calculateTspProductCodeAndEngineCharacteristics; } });
var calculateTariffClassification_1 = require("./calculateTariffClassification");
Object.defineProperty(exports, "calculateTariffClassification", { enumerable: true, get: function () { return calculateTariffClassification_1.calculateTariffClassification; } });
var numberOfAxles_1 = require("./consts/numberOfAxles");
Object.defineProperty(exports, "numberOfAxelsOptions", { enumerable: true, get: function () { return numberOfAxles_1.numberOfAxelsOptions; } });
var co2EmissionClass_1 = require("./consts/co2EmissionClass");
Object.defineProperty(exports, "defaultCO2EmissionClassValue", { enumerable: true, get: function () { return co2EmissionClass_1.defaultCO2EmissionClassValue; } });
Object.defineProperty(exports, "Co2CutoffDate", { enumerable: true, get: function () { return co2EmissionClass_1.Co2CutoffDate; } });
