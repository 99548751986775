import { fromPredicate, map, getOrElse, some, chain, isNone, alt, fromNullable, of, isSome, none, sequenceArray, getOrElseW } from 'fp-ts/Option';
import { pipe, flow } from 'fp-ts/function';
import { has, deleteAt, toEntries, keys, lookup as lookup$1, upsertAt } from 'fp-ts/Record';
import { reduce as reduce$1, lookup, filter } from 'fp-ts/Array';
import { matchW, match } from 'fp-ts/boolean';

function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}

function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}

function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}

function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread2(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}

function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}

function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}

function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}

function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}

var isNumericString = function isNumericString(x) {
  return typeof x === 'string' && /^-?\d+$/.test(x);
};
var deepLookup = function deepLookup(record) {
  return function (path) {
    return pipe((path || '').split('.'), function (keys) {
      var curr = some(record);
      for (var i = 0; i < keys.length; i++) {
        var next = void 0;
        if (isNumericString(keys[i])) {
          next = chain(lookup(parseInt(keys[i])))(curr);
        }
        next = chain(lookup$1(keys[i]))(curr);
        if (isNone(next)) {
          return next;
        }
        curr = next;
      }
      return curr;
    });
  };
};
var isRecord = function isRecord(x) {
  return _typeof(x) === 'object';
};
var isString = function isString(x) {
  return typeof x === 'string';
};
var isPath = function isPath(x) {
  return isString(x) && x.indexOf('.') > -1;
};
var mergeRecords = function mergeRecords(x) {
  return function (y) {
    return pipe(x, toEntries, reduce(y, function (p, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        v = _ref2[1];
      return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, k, v));
    }));
  };
};
var lazyEmptyCssRecord = function lazyEmptyCssRecord() {
  return {};
};
var excludeProp = function excludeProp(key) {
  return function (props) {
    return pipe(props, fromPredicate(function (p) {
      return has(key, p);
    }), map(deleteAt(key)), getOrElse(function () {
      return props;
    }));
  };
};
var excludeProps = function excludeProps(propsToExclude) {
  return function (props) {
    return pipe(propsToExclude, reduce$1(props, function (res, key) {
      return excludeProp(key)(res);
    }));
  };
};
var includeProps = function includeProps(propsToInclude) {
  return function (props) {
    return pipe(props, keys, reduce({}, function (p, k) {
      return pipe(k, fromPredicate(includes(propsToInclude)), map(function () {
        return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, k, props[k]));
      }), getOrElse(function () {
        return p;
      }));
    }));
  };
};
var includes = function includes(arr) {
  return function (el) {
    return arr.includes(el);
  };
};
var reduce = function reduce(initial, fn) {
  return function (arr) {
    return arr.reduce(fn, initial);
  };
};

function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.includes(n)) continue;
    t[n] = r[n];
  }
  return t;
}

function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}

function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}

function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}

var isResponsiveValue = function isResponsiveValue(x) {
  return _typeof(x) === 'object' && Object.keys(x).some(function (k) {
    return k === 'xs';
  });
};
var getValue = function getValue(key, x) {
  if (isResponsiveValue(x)) {
    return x[key];
  }
  return x;
};
var toMediaQueries = function toMediaQueries(key, _ref, curr) {
  var xs = _ref.xs,
    sm = _ref.sm,
    md = _ref.md;
  return function (tokens) {
    var result = _objectSpread2(_objectSpread2({}, curr), {}, _defineProperty({}, key, getValue('xs', xs)));
    if (sm) {
      var n = tokens.breakpoints['sm'];
      var k = "@media screen and (min-width: ".concat(n, ")");
      result = _objectSpread2(_objectSpread2({}, result), {}, _defineProperty({}, k, _objectSpread2(_objectSpread2({}, result[k] || {}), {}, _defineProperty({}, key, getValue('sm', sm)))));
    }
    if (md) {
      var _n = tokens.breakpoints['md'];
      var _k = "@media screen and (min-width: ".concat(_n, ")");
      result = _objectSpread2(_objectSpread2({}, result), {}, _defineProperty({}, _k, _objectSpread2(_objectSpread2({}, result[_k] || {}), {}, _defineProperty({}, key, getValue('md', md)))));
    }
    return result;
  };
};
var transformResponsiveValues = function transformResponsiveValues(tokens) {
  return function (obj) {
    return pipe(obj, toEntries, reduce$1(obj, function (curr, _ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        val = _ref3[1];
      return pipe(val, fromPredicate(isResponsiveValue), map(function (v) {
        return _objectSpread2(_objectSpread2({}, curr), toMediaQueries(key, v, curr)(tokens));
      }), alt(function () {
        return pipe(val, fromPredicate(isRecord), map(function (v) {
          return transformResponsiveValues(tokens)(v);
        }), map(function (v) {
          return _objectSpread2(_objectSpread2({}, curr), {}, _defineProperty({}, key, v));
        }));
      }), getOrElse(function () {
        return curr;
      }));
    }));
  };
};

var pathsCache = new Set([]);
var getPaths = function getPaths(val) {
  var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return pipe(val, fromPredicate(function (v) {
    return isRecord(v);
  }), map(function (v) {
    return pipe(v, toEntries, reduce$1(arr, function (res, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        o = _ref2[1];
      return pipe(isRecord(o) && !isResponsiveValue(o), match(function () {
        return [].concat(_toConsumableArray(res), [key]);
      }, function () {
        return [].concat(_toConsumableArray(res), _toConsumableArray(getPaths(o).map(function (s) {
          return "".concat(key, ".").concat(s);
        })));
      }));
    }));
  }), getOrElse(function () {
    return arr;
  }));
};
var lookupToken = function lookupToken(tokens) {
  return function (path) {
    if (pathsCache.size === 0) {
      pathsCache = new Set(getPaths(tokens));
    }
    var str = "".concat(path);
    var pathToUse = '';
    pathsCache.forEach(function (v) {
      if (str.indexOf(v) > -1) {
        pathToUse = v;
        str = str.replaceAll(v, '#');
      }
    });
    if (str === '#') {
      return pipe(pathToUse, fromPredicate(isPath), chain(deepLookup(tokens)));
    }
    return pipe(pathToUse, fromPredicate(isPath), chain(deepLookup(tokens)), map(function (value) {
      return pipe(value, isResponsiveValue, matchW(function () {
        return str.replaceAll('#', "".concat(value));
      }, function () {
        return Object.entries(value).reduce(function (res, _ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            val = _ref4[1];
          return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, str.replaceAll('#', "".concat(val))));
        }, {});
      }));
    }));
  };
};
var transformTokens = function transformTokens(tokens) {
  return function (obj) {
    return pipe(obj, fromPredicate(function (x) {
      return isRecord(x);
    }), map(toEntries), map(reduce$1(obj, function (p, _ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
        key = _ref6[0],
        val = _ref6[1];
      return pipe(val, lookupToken(tokens), map(function (v) {
        return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, key, v));
      }), alt(function () {
        return pipe(val, transformTokens(tokens), map(function (v) {
          return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, key, v));
        }));
      }), getOrElse(function () {
        return p;
      }));
    })));
  };
};
var parseProps = function parseProps(tokens) {
  return function (prop) {
    return pipe(prop, fromPredicate(function (props) {
      return typeof props === 'function';
    }), map(function (p) {
      return p(tokens);
    }), alt(function () {
      return of(prop);
    }));
  };
};
var fromProps = function fromProps(tokens) {
  return function (prop) {
    return pipe(fromNullable(prop), chain(parseProps(tokens)));
  };
};

var EMPTY_COMPONENT = {};
var SOME_EMPTY_COMPONENT = some(EMPTY_COMPONENT);
var isComponentVariant = function isComponentVariant(x) {
  return !!Object.keys(x).length && Object.entries(x).some(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2);
      _ref2[0];
      var val = _ref2[1];
    return _typeof(val) === 'object' && !isResponsiveValue(val);
  });
};
var lookupRootIfNotVariant = function lookupRootIfNotVariant(val) {
  return pipe(val, fromPredicate(isComponentVariant), chain(flow(lookup$1(':root'), alt(function () {
    return SOME_EMPTY_COMPONENT;
  }))), getOrElse(function () {
    return val;
  }));
};
var lookupVariants = function lookupVariants(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    namespace = _ref4[0],
    variantPath = _ref4[1];
  return function (components) {
    var componentRoot = pipe(namespace, deepLookup(components), map(lookupRootIfNotVariant));
    var componentVariant = pipe(variantPath, deepLookup(components));
    return [componentRoot, componentVariant];
  };
};
var merge = function merge(prev, curr) {
  return Object.entries(curr).reduce(function (res, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      key = _ref6[0],
      val = _ref6[1];
    var pVal = prev[key];
    if (val && pVal && isRecord(val) && isRecord(pVal)) {
      return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, merge(pVal, val)));
    }
    return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, val));
  }, prev);
};
var mergeVariants = function mergeVariants(variants) {
  return pipe(variants, filter(isSome), function (variants) {
    return variants.length === 0 ? [none] : variants;
  }, sequenceArray, map(reduce(EMPTY_COMPONENT, function (prev, curr) {
    return merge(prev, curr);
  })));
};
var lookupComponent = function lookupComponent(component, variant) {
  return function (components) {
    return pipe(components, lookupVariants(!variant ? [String(component)] : [String(component), "".concat(String(component), ".").concat(String(variant))]), mergeVariants);
  };
};

var systemProps = ['m', 'mt', 'mr', 'mb', 'ml', 'mx', 'my', 'p', 'pt', 'pr', 'pb', 'pl', 'px', 'py'];
var translator = {
  m: 'margin',
  mt: 'marginBlockStart',
  mb: 'marginBlockEnd',
  ml: 'marginInlineStart',
  mr: 'marginInlineEnd',
  mx: 'marginInline',
  my: 'marginBlock',
  p: 'padding',
  pt: 'paddingBlockStart',
  pb: 'paddingBlockEnd',
  pl: 'paddingInlineStart',
  pr: 'paddingInlineEnd',
  px: 'paddingInline',
  py: 'paddingBlock'
};
var excludeSystemProps = excludeProps(systemProps);
var extractSystemProps = includeProps(systemProps);
var getProps = function getProps(tokens) {
  return function (props) {
    return pipe(props, fromProps(tokens), getOrElse(function () {
      return props;
    }));
  };
};
var transformSystemProps = function transformSystemProps(props) {
  return pipe(toEntries(props), reduce(props, function (res, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return pipe(translator, lookup$1(key), map(function (transformedKey) {
      return pipe(res, upsertAt(transformedKey, value), deleteAt(key));
    }), alt(function () {
      return pipe(value, fromPredicate(function (v) {
        return isRecord(v) && !isResponsiveValue(v);
      }), map(function (v) {
        return transformSystemProps(v);
      }), map(function (v) {
        return pipe(res, upsertAt(key, v));
      }));
    }), getOrElse(function () {
      return res;
    }));
  }));
};
var fromSystemProps = function fromSystemProps(tokens) {
  return function (props) {
    return pipe(props, extractSystemProps, function (p) {
      return toEntries(p);
    }, reduce({}, function (res, _ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      return pipe(res, upsertAt(key, pipe(value, getProps(tokens))));
    }), transformSystemProps);
  };
};

var fromSxProp = fromProps;

var _excluded = ["component", "variant", "sx"];
var cssProps = ['component', 'variant', 'sx'];
var excludeCssProps = excludeProps(cssProps);
var css = function css(_ref) {
  var component = _ref.component,
    variant = _ref.variant,
    sx = _ref.sx,
    rest = _objectWithoutProperties(_ref, _excluded);
  return function (theme) {
    var compCSS = pipe(fromNullable(component), chain(function (comp) {
      return lookupComponent(comp, variant)(theme.components);
    }), map(transformSystemProps), getOrElseW(lazyEmptyCssRecord));
    var systemPropsCss = fromSystemProps(theme.tokens)(rest);
    var sxCSS = pipe(sx, fromSxProp(theme.tokens), map(transformSystemProps), getOrElseW(lazyEmptyCssRecord));
    return pipe(compCSS, mergeRecords(systemPropsCss), mergeRecords(sxCSS), transformTokens(theme.tokens), map(transformResponsiveValues(theme.tokens)), getOrElseW(lazyEmptyCssRecord), function (c) {
      return Object.keys(c).length > 0 ? c : undefined;
    });
  };
};

var stylingProps = ['sx'];
var stylingPropsWithCss = [].concat(stylingProps, ['css']);
var extendCss = function extendCss(props) {
  return _objectSpread2(_objectSpread2({}, props), {}, {
    css: function css$1(theme) {
      var styles = css({
        sx: props.sx
      })(theme);
      var raw = typeof props.css === 'function' ? props.css(theme) : props.css;
      return [styles, raw].filter(Boolean);
    }
  });
};
var extendAndExclude = function extendAndExclude(props) {
  return pipe(props, extendCss, excludeProps(stylingProps));
};
var hasStylingProps = function hasStylingProps(props) {
  return !!props && stylingPropsWithCss.some(function (propKey) {
    return !!props[propKey];
  });
};
var parseStylingProps = function parseStylingProps(props) {
  return pipe(props, fromPredicate(hasStylingProps), map(extendAndExclude), getOrElse(function () {
    return props;
  }));
};

export { extractSystemProps as a, excludeCssProps as b, css as c, excludeProp as d, excludeSystemProps as e, excludeProps as f, parseStylingProps as p };
