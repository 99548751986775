"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWeightCategory = void 0;
const validators_1 = require("@oresundsbron/validators");
const getWeightCategory = (vehicleId) => {
    switch (vehicleId) {
        case validators_1.VehicleTypeId.Motorcycle:
        case validators_1.VehicleTypeId.PassengerVehicle:
        case validators_1.VehicleTypeId.LightGoodsVehicle:
        case validators_1.VehicleTypeId.BusUnder3500kg:
        case validators_1.VehicleTypeId.MobileHome:
            return validators_1.WeightCategory.Under3500kg;
        case validators_1.VehicleTypeId.HeavyTruck3_5To12Ton:
            return validators_1.WeightCategory.Over3500kgUnder12000kg;
        case validators_1.VehicleTypeId.HeavyTruckOver12Ton:
            return validators_1.WeightCategory.Over12000kgToInfinity;
        case validators_1.VehicleTypeId.MobileHomeOver3500kg:
        case validators_1.VehicleTypeId.BusOver3500kg:
            return validators_1.WeightCategory.Over35000kgToInfinity;
        default:
            return validators_1.WeightCategory.Under3500kg;
    }
};
exports.getWeightCategory = getWeightCategory;
