"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2CutoffDate = exports.defaultCO2EmissionClassValue = exports.co2EmissionClass = void 0;
const validators_1 = require("@oresundsbron/validators");
exports.co2EmissionClass = [
    { id: parseInt(validators_1.Co2EmissionClass.Class_1), label: 'class-1' },
    { id: parseInt(validators_1.Co2EmissionClass.Class_2), label: 'class-2' },
    { id: parseInt(validators_1.Co2EmissionClass.Class_3), label: 'class-3' },
    { id: parseInt(validators_1.Co2EmissionClass.Class_4), label: 'class-4' },
    { id: parseInt(validators_1.Co2EmissionClass.Class_5), label: 'class-5' },
];
exports.defaultCO2EmissionClassValue = validators_1.Co2EmissionClass.Class_1;
exports.Co2CutoffDate = new Date('2019-07-01');
