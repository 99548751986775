"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTspProductCodeAndEngineCharacteristics = void 0;
const fuelTypes_1 = require("./consts/fuelTypes");
const FuelTypeEnums_1 = require("./types/FuelTypeEnums");
const calculateTspProductCodeAndEngineCharacteristics = (fuelType) => {
    const fuel = fuelTypes_1.fuelTypes.find((f) => f.id === fuelType);
    if (!fuel) {
        return { TSPProuctCode: 0, engineCharacteristics: 71 };
    }
    // All these values originates from this ticket:
    // https://dev.azure.com/oresundsbron/osb-web/_workitems/edit/12250
    switch (fuel.label) {
        case FuelTypeEnums_1.FuelTypeEnum.Gasoline:
            return { TSPProuctCode: 1, engineCharacteristics: 2 };
        case FuelTypeEnums_1.FuelTypeEnum.Diesel:
            return { TSPProuctCode: 2, engineCharacteristics: 4 };
        case FuelTypeEnums_1.FuelTypeEnum.GasolineHybrid:
            return { TSPProuctCode: 1, engineCharacteristics: 8 };
        case FuelTypeEnums_1.FuelTypeEnum.DieselHybrid:
            return { TSPProuctCode: 2, engineCharacteristics: 8 };
        case FuelTypeEnums_1.FuelTypeEnum.Electric:
            return { TSPProuctCode: 5, engineCharacteristics: 6 };
        case FuelTypeEnums_1.FuelTypeEnum.Biodiesel:
            return { TSPProuctCode: 2, engineCharacteristics: 4 };
        case FuelTypeEnums_1.FuelTypeEnum.Biogasoline:
            return { TSPProuctCode: 1, engineCharacteristics: 3 };
        case FuelTypeEnums_1.FuelTypeEnum.Hydrogen:
            return { TSPProuctCode: 6, engineCharacteristics: 9 };
        case FuelTypeEnums_1.FuelTypeEnum.NaturalGas:
            return { TSPProuctCode: 4, engineCharacteristics: 5 };
        case FuelTypeEnums_1.FuelTypeEnum.Parafin:
            return { TSPProuctCode: 1, engineCharacteristics: 3 };
        case FuelTypeEnums_1.FuelTypeEnum.Other:
            return { TSPProuctCode: 0, engineCharacteristics: 1 };
        default:
            return { TSPProuctCode: 0, engineCharacteristics: 71 };
    }
};
exports.calculateTspProductCodeAndEngineCharacteristics = calculateTspProductCodeAndEngineCharacteristics;
